<template>
  <div class="login-content">
    <b-image class="p-6" :src="require('@/assets/feeds_logo_green.png')"></b-image>
    <b-field >
      <b-input
        v-model="email"
        type="email"
        icon="account"
        placeholder="Email"
         />
    </b-field>
    <b-field >
      <b-input
        v-model="password"
        type="password"
        icon="lock"
        placeholder="Password"
        password-reveal
        expanded />
    </b-field>
    <b-button type="is-primary" expanded @click="login">Log In</b-button>
  </div>
</template>

<script>
import auth from "@/firebase/auth"

export default {
  name: 'LoginView',
  props: [
  ],
  data() {
    return {
      email: '',
      password: ''
    };
  },
  created: function () {
  },
  methods: {
    login() {
      auth.signInWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          // check if they're admin
          return userCredential.user.getIdTokenResult()
            .then(idTokenResult => {
              userCredential.user.admin = idTokenResult.claims.admin;
              this.$store.commit('setUser', userCredential.user);
            });
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `${error}`});
        })
        .then(() => {
          if (this.$store.state.user.admin) {
            // TODO: go to where we were supposed to be going...
            this.$router.push({name: "MealPlanListView"});
          } else {
            // sign them out completely as they're not admin!
            auth.signOut()
              .then(() => {
                this.$store.commit('setUser', null);
                this.$buefy.toast.open({type: 'is-danger', message: `Admin permission needed`});
              });
          }
        });
    }
  }
}
</script>

<style>
.login-content {
  margin: 10% auto;
  width: 50%;
}
</style>
